import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';

const Datas = [
    {
        image: '/images/why/team.png',
        id: 1,
        title: "Unbeatable Team",
        para: "Brilliant & highly talented team which knows the exact business needs and strategy"
    },
    {
        image: '/images/why/quality.png',
        id: 2,
        title: "The Zenith Standard Of Quality",
        para: "Pixel perfection and code quality are your rights, no compromisations on it, ever."
    },
    {
        image: '/images/why/delivery.png',
        id: 3,
        title: "Responsibility & Timely Delivery",
        para: "Time is the key. we take project responsibility and manage the bandwidth utilization & process management."
    },
    {
        image: '/images/why/communication.png',
        id: 4,
        title: "Communication & Updates",
        para: "Consistent updates to keep you informed about the overall project progress."
    },
    {
        image: '/images/why/coverage.png',
        id: 5,
        title: "Project Coverage",
        para: "Why go back and forth to multiple agencies to accomplish your dream project while we've got you covered from the prototype stage to the deployment stage!"
    },
    {
        image: '/images/why/qa.png',
        id: 6,
        title: "Quality Assurance Verification",
        para: "Each project includes feature-wise pre & post-test cases verification during multiple stages"
    },
    {
        image: '/images/why/privacy.png',
        id: 7,
        title: "Project Privacy",
        para: "Confidentiality matters. we don't use or share any assets which belong to your project, without your permission."
    },
    {
        image: '/images/why/support.png',
        id: 8,
        title: "Support",
        para: "Even after handover, we take care of minor changes or corrections you need without any additional charges once you become part of Axar7."
    },
    {
        image: '/images/why/market.png',
        id: 9,
        title: "market updates",
        para: "We monitor and aim to keep you up to date with the market trends and potential needs."
    },
]




const AboutThree = () => {
    return (
        <div id="why" className="section section-padding bg-color-dark pb--80 pb_lg--40 pb_md--20">
            <div className="container">
                <SectionTitle 
                    subtitle="Our Valus"
                    title="Reasons to Choose Us"
                    description="what sets Axar7 apart from others?"
                    textAlignment="heading-left heading-light-left mb--100"
                    textColor=""
                />

                <div className="row">
                    {Datas.map((data) => (
                        <div className="col-lg-4 col-md-6" key={data.id}>
                            <div className="about-quality">
                                {/* <h3 className="sl-number">{data.id}</h3> */}
                                <h3 className="sl-number">
                                    <img src={process.env.PUBLIC_URL + data.image} alt="Bubble" />
                                </h3>
                                <h5 className="title">{data.title}</h5>
                                <p>{data.para}</p>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
            <ul className="list-unstyled shape-group-10">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-1.png"} alt="Circle" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-7.png"} alt="Circle" /></li>
                {/* <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/lin.png"} alt="Circle" /></li> */}
            </ul>
        </div>
    )
}

export default AboutThree;