import React from 'react';
import { useParams } from 'react-router-dom';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BcrumbBannerTwo from '../elements/breadcrumb/BcrumbBannerTwo';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import { slugify } from '../utils';
import ProjectData from "../data/project/ProjectData.json";
import Accordion from 'react-bootstrap/Accordion';
import { FaCode, FaPalette, FaLayerGroup } from 'react-icons/fa';
import SEO from '../common/SEO';

const allProjectData = ProjectData;

const ProjectDetails = () => {

    const params = useParams();
    const projectSlug = params.slug;

    const getProjectData = allProjectData.filter(data => slugify(data.title) === projectSlug);
    const detailsProject = getProjectData[0];

    const { title, headline, image, specifications, body } = detailsProject

    return (
        <>
            <SEO title={`${title} | Project Details`} />
            <main className="main-wrapper">
                <HeaderOne />
                <BcrumbBannerTwo
                    title={title}
                    paragraph={headline}
                    mainThumb={image}
                />
                <section className="section-padding single-portfolio-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="section-heading heading-left mb-0">
                                    {/* <span className="subtitle">
                                    {detailsProject.category.map((cat, i) => (
                                            <span key={i}>{cat}</span> 
                                        )
                                    )}
                                </span> */}
                                    {/* <h3 className="title">{detailsProject.title} Information</h3> */}
                                    <h3 className="title">Project outline</h3>
                                </div>
                                {body.map((para, index) => (
                                    <p key={index}>{para}</p>
                                ))}
                                {/* <Link to="#" className="axil-btn btn-fill-primary"></Link> */}
                            </div>
                            <div className="col-lg-6 offset-xl-1">
                                <div className="why-choose-us">
                                    <div className="section-heading heading-left">
                                        <h3 className="title">Specifications</h3>
                                    </div>
                                    <Accordion defaultActiveKey={["1", "2", "3"]} alwaysOpen={true}>

                                        {
                                            specifications && 'style' in specifications && (
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header><FaLayerGroup /> Strategy</Accordion.Header>
                                                    <Accordion.Body>
                                                        {specifications['style'].join(', ')}
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            )
                                        }
                                        {
                                            specifications && 'design' in specifications && (
                                                <Accordion.Item eventKey="2">
                                                    <Accordion.Header><FaPalette /> Design</Accordion.Header>
                                                    <Accordion.Body>
                                                        {specifications['design'].join(', ')}
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            )
                                        }
                                        {
                                            specifications && 'development' in specifications && (
                                                <Accordion.Item eventKey="3">
                                                    <Accordion.Header><FaCode /> Development</Accordion.Header>
                                                    <Accordion.Body>
                                                        {specifications['development'].join(', ')}
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            )
                                        }
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <VideoOne /> */}
                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default ProjectDetails;