import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';
import CounterUp from '../../component/counterup/CounterUp';

const platform_data = [
    {
        id: 1,
        rating: 5,
        logo: "/images/others/freelancer.svg",
        additionals: [
            'Jobs Completed: 100%',
            'On Time: 100%',
            'On Budget: 100%'
        ]
    },
    {
        id: 1,
        rating: 5,
        logo: "/images/others/fiverr.svg",
        additionals: [
            'Seller communication level: 5/5',
            'Recommend to a friend: 5/5',
            'Service as described: 5/5'
        ]
    },
    {
        id: 3,
        rating: 4.9,
        logo: "/images/others/upwork.svg",
        additionals: [
            'Relatively new',
            'platoform for us'
        ]
    }
]


const CounterUpOne = () => {
    return (
        <div id="growth" className="section section-padding-minimal bg-color-dark">
            <div className="container">
                <SectionTitle 
                    subtitle="Highlights"
                    title="Crazy Growth!"
                    description="Just a few glimpses on how we exceeded the expectations"
                    textAlignment="heading-light"
                    textColor=""
                />
                <div className="row">
                    <CounterUp colSize="col-lg-3 col-6" layoutStyle="" evenTopMargin="" />
                </div>
                
                <hr className="platform-hr" />
                
                <div className="row platform-review">
                    {
                        platform_data.map(platform_item => (
                            <div className="col-lg-4 col-4" key={platform_item.id}>
                                <div className="platform-review-container">
                                    <div className="platform-review-top-container">
                                        <div className="platform-review-holder">
                                            <p className="review-digit">{platform_item.rating}<span className="review-out-of">/5</span></p>
                                        </div>
                                    </div>
                                    <div className="platform-review-mid-container">
                                        <img alt="platform" src={process.env.PUBLIC_URL + platform_item.logo} />
                                    </div>
                                    <div className="platform-review-bottom-container">
                                        {
                                            platform_item.additionals.map((info, index) => <h6 key={index} className="platform-attributes">{info}</h6>)
                                        }                                        
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>

            </div>
        </div>
    )
}


export default CounterUpOne;