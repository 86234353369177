import React, { useEffect, useState } from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';
import ProcesstData from "../../data/process/ProcessData.json";
import Tilty from 'react-tilty';

const getProcesstData = ProcesstData;

const billed = [
    {
        id: 1,
        label: "Design"
    },
    {
        id: 2,
        label: "Development"
    }
]

const ProcessOne = () => {
    const [isActive, setActive] = useState("design");

    useEffect(() => {
        setActive(billed[0].label.toLowerCase());
    }, []);


    const handleChange = (e) => {
        setActive(e.target.textContent.toLowerCase());
    };

    return (
        <div className="section section-padding bg-color-light pb--70">
            <div className="pricing-billing-duration">
                <ul>
                    {billed.map((data) => (
                        <li className="nav-item" key={data.id}>
                            <button onClick={handleChange} className={`nav-link ${data.label.toLocaleLowerCase() === isActive ? "active": ""}`}>{data.label}</button>
                        </li>
                    ))}
                </ul>
            </div>
            <SectionTitle 
                subtitle="Process"
                title={`The ${isActive} process we follow`}
                description="We follow different process specific to your project requirements, but on a higher level, these are the in general steps"
                textAlignment=""
                textColor="mb--90"
            />
            <div className="container">
                {getProcesstData[isActive].map((data) => (
                    <div key={data.id} className={`process-work ${(data.id % 2  === 0) ? "content-reverse" : ""}`}>
                        <Tilty perspective={5000} max={25} reset={true} speed={5000} scale={0.97}>
                            <div className="thumbnail">
                                <img src={process.env.PUBLIC_URL + data.thumb} alt="Thumbnail" />
                            </div>
                        </Tilty>
                    <div className="content">
                        <span className="subtitle">{data.subtitle}</span>
                        <h3 className="title">{data.title}</h3>
                        <p>{data.paragraph}</p>
                    </div>
                </div>
                ))}
            </div>
            <ul className="shape-group-17 list-unstyled">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-24.png"} alt="Bubble" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-23.png"} alt="Bubble" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="Line" /></li>
                <li className="shape shape-4"><img src={process.env.PUBLIC_URL + "/images/others/line-5.png"} alt="Line" /></li>
                <li className="shape shape-5"><img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="Line" /></li>
                <li className="shape shape-6"><img src={process.env.PUBLIC_URL + "/images/others/line-5.png"} alt="Line" /></li>
            </ul>
        </div>
    )
}

export default ProcessOne;