import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BannerTwo from '../component/banner/BannerTwo';
import CounterUpOne from '../component/counterup/CounterUpOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ProjectOne from '../component/project/ProjectOne';
import ServiceProp from '../component/service/ServiceProp';
import SectionTitle from '../elements/section-title/SectionTitle';
import ServiceData from "../data/service/ServiceMain.json";
import { slugify } from '../utils';
import { FaAngleRight } from "react-icons/fa";
import AboutThree from '../component/about/AboutThree';
import { Link } from 'react-router-dom';

const allData = ServiceData;

const Index = () => {

    const developmentData = allData.filter(data => slugify(data.type ? data.type : "") === "services");

    return (
        <>
            <SEO title="Axar7" />
            <main className="main-wrapper">
                
                <HeaderOne />

                <BannerTwo />

                <div className="section section-padding" id="services">
                    <div className="container">
                        <SectionTitle
                            subtitle="we can help you with"
                            title="Services"
                            description=""
                            textAlignment="heading-left"
                            textColor=""

                        />
                        <div className="row">
                            <ServiceProp colSize="col-lg-4 col-md-6" serviceStyle="service-style-2" serviceData={developmentData} />
                        </div>
                    </div>
                </div>

                <CounterUpOne />

                <ProjectOne colSize="col-xl-4 col-md-6" columnGap="row-15" />

                <AboutThree />

                <div className="section section-padding bg-color-light spalsh-why-choose" id="splash-why-choose">
                    <div className="container">
                        <SectionTitle
                            subtitle="Impact"
                            title="Explore more"
                            textAlignment="heading-left"
                            textColor=""
                        />

                        <div className="explore-more row justify-content-center">
                            <div className="col-xl-4 col-lg-6">
                                <div className="support-box online-docuentation splash-hover-control">
                                    <Link to="/process">
                                        <div className="inner">
                                            <div className="content">
                                                <div className="heading">
                                                    <h4 className="title">The Process <br /> we follow</h4>
                                                    <div className="icon">
                                                        <img src={process.env.PUBLIC_URL + "/images/icon/process.png"} alt="Thumb" />
                                                    </div>
                                                </div>
                                                <p>Know the exact specific processes we follow that meet your business expectations.</p>
                                            </div>
                                            <div className="btn-area">
                                                <span className="item-btn"><FaAngleRight /></span>
                                            </div>
                                        </div>
                                        <ul className="shape-group list-unstyled">
                                            <li className="shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-34.png"} alt="Shape" /></li>
                                            <li className="shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-8.png"} alt="Shape" /></li>
                                        </ul>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6">
                                <div className="support-box support-ticket splash-hover-control">
                                    <Link to="/testimonials">
                                        <div className="inner">
                                            <div className="content">
                                                <div className="heading">
                                                    <h4 className="title">Project <br /> Feedbacks</h4>
                                                    <div className="icon">
                                                        <img src={process.env.PUBLIC_URL + "/images/icon/review.png"} alt="Thumb" />
                                                    </div>
                                                </div>
                                                <p>We treat our customers as our partners in our growth journey. Explore what they think about being a part of Axar7.</p>
                                            </div>
                                            <div className="btn-area">
                                                <span className="item-btn"><FaAngleRight /></span>
                                            </div>
                                        </div>

                                        <ul className="shape-group list-unstyled">
                                            <li className="shape-3"><img src={process.env.PUBLIC_URL + "/images/others/feedback-circle.png"} alt="Shape" /></li>
                                            <li className="shape-2"><img src={process.env.PUBLIC_URL + "/images/others/feedback.png"} alt="Shape" /></li>
                                        </ul>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="line-shape">
                        <img src={process.env.PUBLIC_URL + "/images/others/line-6.png"} alt="Line" />
                    </div>
                </div>

                <CtaLayoutOne />

                <FooterOne parentClass="" />

            </main>
        </>
    )
}

export default Index;
