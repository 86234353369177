import { toast } from 'react-toastify';

const email_endpoint = 'https://e0bgntvze9.execute-api.us-east-1.amazonaws.com/send_email'

function flatDeep(arr, d = 1) {
  return d > 0 ? arr.reduce((acc, val) => acc.concat(Array.isArray(val) ? flatDeep(val, d - 1) : val), [])
               : arr.slice();
}

const slugify = function(text) {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(/[^\w-]+/g, '') // Remove all non-word chars
      .replace(/--+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, '') // Trim - from end of text
}

function containsObject(obj, list) {
  var i;
  for (i = 0; i < list.length; i++) {
  console.log()
      if (list[i].slug === obj.slug) {
          return i;
      }
  }

  return -1;
}

const beInTouch = async function (type, meta) {
  const { email, user_name, subject } = meta
  //eslint-disable-next-line
  const rex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  if (!rex.test(email)) {
    toast.error('Please enter a valid email address')
    return false
  }

  const payload = {
    type,
    user_email: email
  }

  if (type === 'reach') {
    if (user_name && user_name.trim().length > 0) {
      payload['user_name'] = user_name
    }
    if (subject && subject.trim().length > 0) {
      payload['subject'] = subject
    }
  }
  
  const is_sent = await sendInquiry(payload)
  if (is_sent) {
    toast(`Thanks! We will reach out to you on ${email} in 24 hours.`)
    return true
  } else {
    toast(`Oops! something went wrong. Please email to 'business@axar7.com'`)
    return false
  }
}


const sendInquiry = async payload => {
  let status = false
  try {
    const result = await fetch(email_endpoint, {
      method: 'post',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
    if (result.status === 200) {
      status = true
    }
  } catch (error) {
    status = false
    console.error(error)
  } finally {
    return status
  }
}

export {flatDeep, slugify, containsObject, beInTouch};