import React from 'react';
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import ScrollToTop from './component/scrollToTop/ScrollToTop';
import 'bootstrap/dist/css/bootstrap.min.css';

// Project
import ProjectDetails from './pages/ProjectDetails';

// Pages
import Testimonials from './pages/Testimonials';
import Process from './pages/Process';
import Contact from './pages/Contact';
import ErrorPage from './pages/404';

// Css Import
import './assets/scss/app.scss';
import Index from './pages/Index';


const App = () => {
  return (
    <Router>
		<ScrollToTop>
			<Routes>
				<Route path={process.env.PUBLIC_URL + "/"} element={<Index />}/>
				<Route path={process.env.PUBLIC_URL + "/contact"} element={<Contact />}/>
				<Route path={process.env.PUBLIC_URL + "/testimonials"} element={<Testimonials />}/>
				<Route path={process.env.PUBLIC_URL + "/process"} element={<Process />}/>

				<Route path={process.env.PUBLIC_URL + "/project-details/:slug"} element={<ProjectDetails />}/>

				<Route path={process.env.PUBLIC_URL + "/404"} element={<ErrorPage />}/>
				{/* default 404 */}
				<Route path="*" element={<Navigate to="/404" replace />} />

			</Routes>
		</ScrollToTop>
    </Router>
  )
}

export default App;
