import React, { useState } from 'react';
import { beInTouch } from '../../utils';

const FormTwo = () => {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [subject, setSubject] = useState('')

    const sendEmail = async () => {
        const is_sent = await beInTouch('reach', {email, user_name: name, subject})
        if (is_sent) {
            setName('')
            setEmail('')
            setSubject('')
        }
    }

    return (
        <div className="axil-contact-form">
        <div className="form-group">
            <label>Name</label>
            <input value={name} autoFocus type="text" className="form-control" name="contact-name" onChange={e => setName(e.target.value)} />
        </div>
        <div className="form-group">
            <label>Email</label>
            <input value={email} type="email" className="form-control" name="contact-email" required onChange={e => setEmail(e.target.value)} />
        </div>
        {/* <div className="form-group">
            <label>Phone</label>
            <input type="tel" className="form-control" name="contact-phone" required />
        </div> */}
        <div className="form-group mb--40">
            <label>How can we help you?</label>
            <textarea value={subject} className="form-control" name="contact-message" rows="4" onChange={e => setSubject(e.target.value)}></textarea>
            
        </div>
        <div className="form-group">
            <button onClick={() => sendEmail()} type="submit" className="axil-btn btn-fill-primary btn-fluid btn-primary" name="submit-btn">Send Message</button>
        </div>

    </div>
    )
}

export default FormTwo;