import React from 'react';
import { Link } from 'react-router-dom';
import { FaAngleDown } from "react-icons/fa";


const Nav = () => {
    const smoothScrollTo = function(id) {
        const ele = document.getElementById(id)
        ele.scrollIntoView({ behavior: 'smooth' });
    }
    
    return (
        <nav className="mainmenu-nav">
            <ul className="mainmenu">
                <li className="menu-item-has-children">
                    <Link to="/" onClick={() => smoothScrollTo('home')}>Home</Link>
                </li>
                <li className="menu-item-has-children">
                    <Link to="/#services" onClick={() => smoothScrollTo('services')}>Services</Link>
                </li>
                <li className="menu-item-has-children">
                    <Link to="/#growth" onClick={() => smoothScrollTo('growth')}>Our Growth</Link>
                </li>
                <li className="menu-item-has-children">
                    <Link to="/#projects" onClick={() => smoothScrollTo('projects')}>Projects</Link>
                </li>
                <li className="menu-item-has-children">
                    <Link to="/#why" onClick={() => smoothScrollTo('why')}>Why Us</Link>
                </li>
                <li className="menu-item-has-children">
                    <Link to="#">More <FaAngleDown /></Link>
                    <ul className="axil-submenu">
                        <li><Link to={process.env.PUBLIC_URL + "/process"}>Process We Follow</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/testimonials"}>Project Feedbacks</Link></li>
                    </ul>
                </li>
                <li><Link to={process.env.PUBLIC_URL + "/contact"}>Contact</Link></li>
            </ul>
        </nav>
    )
}

export default Nav;