import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ProcessOne from '../component/process/ProcessOne';


const ProcessComp = () => {

    return (
        <>
            <SEO title="Process | Axar7" />
            <main className="main-wrapper">
                <HeaderOne />
                <div className="section section-padding-minimal">
                    <ProcessOne />
                    <ul className="shape-group-3 list-unstyled">
                        <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-4.png"} alt="shape" /></li>
                    </ul>
                </div>
                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default ProcessComp;