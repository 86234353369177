import React, { useState } from 'react';
// import { FaBehance, FaDribbble, FaFacebookF, FaInstagram, FaLinkedin, FaPinterestP, FaTwitter, FaVimeoV } from "react-icons/fa";
import { FaEnvelopeOpen } from "react-icons/fa";
import { beInTouch } from '../../utils';
import { ToastContainer } from 'react-toastify';

const FooterOne = ({parentClass}) => {
    const [email, setEmail] = useState('');

    const sendEmail = async () => {
        const is_sent = await beInTouch('hi', {email})
        if (is_sent) {
            setEmail('')
        }
    }
    
    return (
        <footer id="footer" className={`footer-area ${parentClass}`}>
            <div className="container">
                {/* <div className="footer-top">
                    <div className="footer-social-link">
                        <ul className="list-unstyled">
                            <li><Link to="https://facebook.com/"><FaFacebookF /></Link></li>
                            <li><Link to="https://twitter.com/"><FaTwitter /></Link></li>
                            <li><Link to="https://www.pinterest.com/"><FaPinterestP /></Link></li>
                            <li><Link to="https://www.linkedin.com/"><FaLinkedin /></Link></li>
                            <li><Link to="https://www.instagram.com/"><FaInstagram /></Link></li>
                            <li><Link to="https://vimeo.com/"><FaVimeoV /></Link></li>
                            <li><Link to="https://dribbble.com/"><FaDribbble /></Link></li>
                            <li><Link to="https://www.behance.net/"><FaBehance /></Link></li>
                        </ul>
                    </div>
                </div> */}
                <div className="footer-main">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6">
                            <div className="row">
                                <div className="col-12">
                                    <div className="footer-art" />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6">
                            <div className="footer-widget border-end">
                                <div className="footer-newsletter">
                                    <h2 className="title">Get in touch!</h2>
                                    <p>Enough Talk, Let's build something wonderful together!</p>
                                    <div className="input-group">
                                        <span className="mail-icon"><FaEnvelopeOpen /> </span>
                                        <input value={email} type="email" className="form-control" placeholder="Email address" onChange={e => setEmail(e.target.value)} />
                                        <button className="subscribe-btn" type="submit" onClick={() => sendEmail()}>Subscribe</button>
                                    </div>
                                    <br />
                                    <div className="footer-copyright">
                                        <span className="copyright-text">© {new Date().getFullYear()}. All rights reserved by <a href="https://axar7.com">Axar7</a></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={8000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </footer>
    )
}

export default FooterOne;