import React from 'react';
import StackGrid from "react-stack-grid";
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import SectionTitle from '../elements/section-title/SectionTitle';

const reviews = {
    'freelancer': [
        `Thanks for the superb original website design work! Highly appreciate the quick responses and timely completion & delivery. Look forward to work on new projects with the freelancer!`,
        `Great service provider and quality work. I'll recommend him for your design work`,
        `Highly recommended - truly amazing and a great personality. Excellent customer service, can't do enough to help! :)`,
        `A+ Highly Recommended Thanks for your help`,
        `A+++ An amazing talent, would highly recommend. Excellent Customer Service skill and fantastic design skills.`,
        `This guy is amazing!`,
        
    ],
    'upwork': [
        `Very good. Would definitely recommend for the full stack projects!`,
    ],
    'fiverr': [
        `Really good result, will be order again for the next project`,
        `Done as required in a timely fashion.`,
        `great person no troubles delivery fast`,
        `I got exactly what i was looking for and an on time delivery. I definitely recommend this seller`,
        `Did everything I asked for and was very polite! Pleasure doing business with you!`,
        `Amazing seller, over-delivered in a quick time!`,
        `Great service and quick fix. Will be sure to order again.`,
        `Outstanding expereince! One of the best sellers on fiverr for sure. Will reorder again soon.`,
        `Amazing experience and great seller. I have worked work Abhishek on many of my designing projects and he has always been patient and they have worked out better than I could have imagined.`,
        `Amazing expereince as always! Will be coming back soon, thanks.`,
        `Great experience. Very professional and worked with me to get this done in time. Thanks. Will order again.`,
        `Thanks for doing this project! It looks great and revision after revision he continued to accept them with no problems. Will be working with Abhishek again soon.`,
        `I love this designer! He continues to impress me on each design, he has nice communication and is great to work with in general.`,
        `Amazing experience working with this seller. Very prompt and very helpful. Met all of my needs lightning fast, and left me with a beautiful website mockup. 10/10 would work with again.`,
        `Wow!! Super fast & absolutely perfect! Thank you so much!`,
        `This was an amazing experience, I will be sure to hire him again! I would really recommend him for anyone wanting a professional design!`,
        `Definetly the best among the lot. i was more than satisfied with your work. LONG WAY TO GO !. I wish you all the success. Definetly will get all my work from him.`,
        `Excellent work. Very excited to implement his designs in my iOS application!`,
        `Very pleased. He did an excellent job redesigning my iOS app. Will work with seller again!`,
        `The product delivered was amazing and the changes made to it, were perfect. thank you so much!`,
    ]
}

const Testimonials = () => {

    return (
        <>
            <SEO title="Testimonials" />
            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne
                    title="Some of the Customer Reviews"
                    page="Reviews"
                />

                <StackGrid
                    className="reviews-holder"
                    appearDelay={200}
                    gutterHeight={40}
                    gutterWidth={40}
                    duration={1000}
                    columnWidth={400}
                >
                    {
                        Object.keys(reviews).map(platform => (
                            reviews[platform].map((review, index) =>
                                <div className={`review-container ${platform}`} key={index}>
                                    <p className="review">
                                        <span className="quote">"</span>
                                        {review}</p>
                                        <span className="quote">"</span>
                                    <img alt="platform" src={process.env.PUBLIC_URL + `/images/others/${platform}.svg`} />
                                </div>
                            )
                        ))
                    }
                </StackGrid>

                <div className="section section-padding customer-review-area overflow-hidden">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <SectionTitle
                                    subtitle="We're not done yet!"
                                    title="There are 200+ more!"
                                    description="Reach out to us in case you want to get more review information or want to check the review authenticity"
                                    textAlignment="heading-left"
                                    textColor=""
                                />
                            </div>
                            <div className="col-lg-4">
                                <div className="review-site-logo">
                                    <img src={process.env.PUBLIC_URL + "/images/others/more-review.png"} alt="more-reviews-img" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul className="shape-group-11 list-unstyled">
                        <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/line-6.png"} alt="line" /></li>
                        <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/circle-3.png"} alt="line" /></li>
                    </ul>
                </div>

                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default Testimonials;